
#full-conclusion-section {
    background: url("../static/img/double-bubble-outline.png");
    padding-top: 7rem;
    margin-top: -4.2rem;
}

.conclusion-container {  
    display: grid;
    grid-template-rows: .2fr .8fr 1.6fr .8fr .8fr;
    grid-template-columns: 2fr 1.3fr .8fr;
    gap: 0px 0px;
    grid-auto-flow: row;
  }
  
  .text-bg { 
    grid-area: 3 / 1 / 4 / 3;
    background-color: white;
    border-bottom: 3px solid #00B0AA;
 }

 .text {
    grid-area: 3 / 1 / 4 / 2;
    padding-left: 4rem;
}

.picture {
    grid-area: 2 / 2 / 5 / 3;
}
  
.icons {
    flex-direction: row;
    grid-area: 2 / 3 / 5 / 4;
    justify-content: center;
    align-items: center;
    display: flex;
}

.icons a {
    color:black;
    font-size: 2.7rem;
    margin: 1rem;

}

.icons a:hover {
    color: lightgray;
}

#conclusion-banner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

#portrait {
    width: 70%;
    object-fit: cover;
}

#conclusion-right-picture, #conclusion-right-picture a {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 750px) {
    .conclusion-container {
        display: grid; 
        grid-auto-columns: 1fr; 
        grid-template-columns: 1fr; 
        grid-template-rows: .5fr 1fr .5fr; 
        gap: 0px 0px; 
      }
      .text-bg { grid-area: 1 / 1 / 2 / 2; }
      .picture { grid-area: 2 / 1 / 3 / 2; }
      .icons { 
        grid-area: 3 / 1 / 4 / 2; 
        flex-direction: row;
        justify-content: space-around;
    }
    
      .text { 
        grid-area: 1 / 1 / 2 / 2;
        padding: 0 2rem 
    }
      #portrait { 
        width: 60%;
        margin-top: 5em;
     }

}

